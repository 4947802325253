<template>
  <div class="md:p-4 max-w-3xl">
    <div class="p-4">
      <sqr-page-header title="Demandes d'offres">
        <router-link :to="{ name: 'inquiry-models', params: { bid } }">
          <sqr-button icon="cog" label="Modèles" color="light" />
        </router-link>
        <router-link :to="{ name: 'inquiry-start', params: { bid } }">
          <sqr-button icon="plus" label="Ajouter une demande" color="primary" />
        </router-link>
      </sqr-page-header>
    </div>

    <div class="bg-white shadow overflow-hidden sm:rounded-md">
      <ul>
        <li
          v-for="inq in inquiries"
          :key="inq.id"
          class="border-t first:border-t-0"
        >
          <router-link
            :to="{
              name: inq.status === 'draft' ? 'inquiry-fill' : 'inquiry-close',
              params: { bid, iid: inq.id }
            }"
            class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out"
          >
            <div class="px-4 py-4 sm:px-6">
              <div class="flex items-center justify-between">
                <div
                  class="text-sm leading-5 font-medium text-blue-600 truncate"
                >
                  {{ inq.name }}
                </div>
                <div class="ml-2 flex-shrink-0 flex">
                  <span
                    class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800"
                  >
                    {{ inq.status }}
                  </span>
                </div>
              </div>
              <div class="mt-2 sm:flex sm:justify-between">
                <div class="sm:flex">
                  <div
                    class="mr-6 flex items-center text-sm leading-5 text-gray-500"
                  >
                    <fa
                      :icon="['far', 'tag']"
                      class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    />
                    {{ inq.model && inq.model.name }}
                  </div>
                  <div
                    class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0"
                    v-if="inq.recipientIds && inq.recipientIds.length > 0"
                  >
                    <fa
                      :icon="['far', 'envelope']"
                      class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    />
                    {{ inq.recipientIds.length }}
                  </div>
                </div>
                <div
                  class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0"
                >
                  <span v-if="inq.updated" class="mx-1 flex items-center">
                    <fa
                      class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      :icon="['far', 'user']"
                    />
                    {{ inq.updated && inq.updated.displayName }}
                  </span>
                  <span v-if="inq.updated" class="mx-1 flex items-center">
                    <fa
                      class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      :icon="['far', 'calendar']"
                    />
                    <span>
                      maj <sqr-datetime :value="inq.updated.date" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>

    <sqr-pagination
      class="mt-3"
      v-bind="pagination || {}"
      @page-set="pageSet"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SqrPageHeader from '@/sqrd-ui/SqrPageHeader';
import SqrButton from '@/sqrd-ui/SqrButton';
import SqrDatetime from '@/sqrd-ui/SqrDatetime';
import SqrPagination from '@/sqrd-ui/SqrPagination';
import broker from '../broker';

export default {
  name: 'Inquiries',
  mixins: [broker],
  components: { SqrPageHeader, SqrButton, SqrDatetime, SqrPagination },
  computed: {
    ...mapState('inquiries', { inquiries: 'docs' }),
    ...mapGetters('inquiries', ['pagination'])
  },
  mounted() {
    const bid = this.bid;
    this.sub({
      path: `brokers/${bid}/inquiries`,
      orderBy: [['updated.date', 'desc']]
    });
  },
  methods: {
    ...mapActions('inquiries', ['sub', 'pageSet', 'unsub'])
  },
  metaInfo: {
    title: "Demandes d'Offres"
  }
};
</script>
